.new-patients-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url("../../../../assets/banners/new-patients.jpg");
  color: white;
  text-align: center;
}

.new-patients-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1em;
  margin-bottom: 1em;
}

.new-patients-buttons {
  grid-column: 1/4;
  text-align: center;
  margin: 1em;
}

.new-patients-buttons button {
  padding: .3em .7em;
  margin: 1em;
  font-size: 1.2em;
  color: white;
  background-color: orange;
  border: white solid 1px;
  border-radius: 2px;
  transition: box-shadow .3s;
}

.new-patients-buttons button:hover {
  box-shadow: 5px 5px black;
}

.new-patients-content article {
  grid-column: 1/3;
}

.new-patients-content li {
  padding: 0.2em;
  font-size: 1.2em;
}

.kid-toothbrush-smile {
  grid-column: 3/4;
  grid-row: 2/3;
  justify-self: center;
  align-self: center;
  margin-top: 200px;
  transform: rotate(5deg);
}

@media screen and (max-width: 926px) {

  .new-patients-header {
    background-size: cover;
    background-position: 80%;
  }

.girl-rabbit {
  margin-top: 0;
}

.toothbrush {
  display: none;
}
}