.covid-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url("../../../assets/banners/covid.jpg");
  color: white;
  text-align: center;
}

.covid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1em;
}

.covid-content article {
  grid-column: 1/3;
  padding: 0 2em 1em 1em;
}

.covid-content ul {
  font-size: 1.1em;
}

.covid-content li {
  padding: 0.2em;
}

.covid-survey-btn-wrapper {
  grid-column: 2/3;
  padding: 0.5em;
  margin-bottom: 5px;
  text-align: center;
}

.covid-survey-btn-wrapper a {
  color: white;
}

.covid-content .before-visit {
  grid-column: 1/4;
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}

.before-visit header {
  padding: 1em 0;
}

.covid-btns {
  display: flex;
  justify-content: space-around;
  margin: 1.5em;
}

.covid-btns button {
  height: 50px;
  width: 200px;
  font-size: 1.1em;
  color: white;
  background-color: orange;
  border-radius: 2px;
  border: none;
  transition: box-shadow .2s;
}

.covid-btns button:hover {
  box-shadow: 5px 5px 1px black;
}

.mask {
  grid-column: 3/4;
  grid-row: 4/6;
  align-self: center;
  justify-self: center;
  transform: rotate(5deg);
}

@media screen and (max-width: 926px) { 

  .covid button {
    margin: 1em;
  }

  .covid-survey-btn {
    padding: .2em;
  }

  .covid-btns {
    flex-direction: column;
  }

 }