.printables-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat 30% 70%/100% url("../../../../assets/banners/kid-reading.jpg");
  color: white;
  text-align: center;
}

.printables .printables-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
}

.printables .printables-content button {
  padding: 1.5em;
  margin: 1em;
  box-shadow: 0 0 2px grey;
  background-color: white;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  transition: transform .5s;
}

.printables-content h4 {
  text-align: center;
  margin: 5px 0 0 0;
  color: #12B4EB;
}

.printables-content svg {
  fill: #12B4EB
}

.printables button:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 926px) {

  .printables-header {
    background-size: cover;
  }

}