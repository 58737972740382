.reviews-home {
    position: relative;
    margin-bottom: 4px;
    height: 120px;
    background-color: rgb(73, 73, 243);
    color: white;
}

.reviews-slideshow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 0;
    list-style: none;
    font-size: 1.5em;
}

.reviews-slideshow span {
   font-family: 'Shadows Into Light Two', cursive;
}

.slide {
    position: absolute;
    opacity: 0;
    z-index: 1;
    transition: opacity .8s;
}

.showing {
    opacity: 1;
    z-index: 2;
}

.google-reviews {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: white;
    font-family: "Quicksand", sans-serif;
}

.google-reviews a {
    color: white;
}

.google-reviews a:hover {
    color: orange;
    text-decoration: none;
}

/* Media Queries */

@media screen and (max-width: 926px) {
    .reviews-home {
        height: 100%;
    }

    .reviews-slideshow {
        padding: 4em 1em;
        font-size: 1.2em;
        margin-bottom: 0;
    }

    .slide {
        padding: 0 .5em;
    }
}