#woman-smile {
  padding: 2em;
}

.meet-the-team h3 {
  margin: 0.5em;
}

.meet-the-team h4 {
  margin: 0.5em;
}

.meet-the-team-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../../../assets/banners/exterior-wide.jpg") no-repeat center;
  color: white;
  background-size: cover;
}

.meet-the-team .employees {
  margin-top: 1em;
}

.employees article {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1em 0;
  margin: 2em 0;
  justify-items: center;
}

.employees article header {
  grid-column: 1/3;
  text-align: center;  
}

.employees img {
  width: 15vw;
  height: 15vw;
  padding: 3px;
  object-fit: cover;
  border: #C731C7 solid 3px;
  border-radius: 50%;
  box-shadow: 2px 2px 5px grey;
  grid-column: 1/3;
}

.employees p {
  grid-column: 3/7;
  grid-row: 1/3;
  align-self: center;
}

@media screen and (max-width: 926px) { 
  .employees article {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
  }

  .employees article h3 {
    margin-left: 0;
    margin-right: 0;
  }

  .employees img {
    width: 40vw;
    height: 40vw;
    margin: .7em 0;
  }

  .olivia-header {
    padding: 0;
  }
 }
