.faq-header {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url("../../../../assets/banners/faq.jpg");
  color: white;
  text-align: center;
}

.faq-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.faq-content article {
  grid-column: 1/3;
  padding: 1em 2em 1em 1em;
}

.dr-sophie-funny {
  grid-column: 3/4;
  grid-row: 1/5;
  object-position: top;
  align-self: center;
  justify-self: center;
  transform: rotate(5deg);
}

.faq-footer {
  padding: 1em 0;
  margin: 1em 5em;
  padding: 0 2em 1em 1em;
  border-radius: 5px;
  font-weight: bold;
  background-color: white;
}

.faq-footer a {
  color: #C731C7;
}

.faq-footer a:hover {
  color: orange;
  text-decoration: none;
}

@media screen and (max-width: 926px) { 

  .faq-header {
    background-size: cover;
  }

  .faq-footer {
    margin: 0;
  }
 }