@import url(https://fonts.googleapis.com/css2?family=Bentham&display=swap);
@font-face {
  font-family: "Scream-It-Loud";
  src: local("Scream-It-Loud"),
    url(/static/media/sream-it-loud.f77c3fbb.ttf) format("truetype");
}
body {
  padding: 0;
  margin: 0;
}

.main-header {
  z-index: 10;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  background-color: white;
  border-bottom: black 1px solid;
  box-shadow: 0 0 2px grey;
  max-width: 1500px;
}

.header-contact {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  color: white;
}

.header-title {
  flex-basis: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 5px 0;
  font-size: 1.9em;
  font-family: 'scream-it-loud';
  background-color: orange;
  text-align: center;
  border-right: black 1px solid;
  border-bottom: black 1px solid;
}

.header-title a {
  color: white;
}

.header-title a:hover {
  color: rgb(199, 12, 199);
  text-decoration: none;
}

.header-phone {
  flex-basis: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  margin: 0;
  padding: 6px 0;
  font-size: 1.3em;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  background-color: rgb(18, 180, 235);
  border-right: black 1px solid;
  border-bottom: black 1px solid;
}

.header-phone a {
  width: 25%;
}

.header-phone svg {
  width: 32%;
  fill: white;
}

.header-phone svg:hover {
  fill: rgb(199, 12, 199);
}

.header-social {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  background-color: rgb(199, 12, 199);
  border-bottom: black 1px solid;
  text-align: center;
}

.header-social a {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-social svg {
  height: 60%;
  fill: white;
  background-color: rgb(199, 12, 199);
  border-radius: 30%;
  padding: 3px;
  transition: transform .2s;
}

.header-social svg:hover {
  fill: orange;
}

.nav-logo {
  height: 90px;
}

.navbar {
  margin: 0 7em;
}

.navbar a {
  font-family: 'Handlee', cursive !important;
  font-weight: 600;
  color: black !important;
}

.custom-background {
  background-color: white;
}

@media screen and (max-width: 991px) {
  .navbar {
    margin: 0;
  }

  .main-header {
    height: 101px;
  }

  .navbar-light .navbar-toggler {
    border: none;
  }

  #responsive-navbar-nav {
    z-index: 10;
    margin-top: 7px;
    border: solid 1px black;
    background-color: white;
  }

  .custom-background {
    padding: 0 !important;
  }

  #mobile-button {
    width: 17%;
  }

  .header-contact {
    flex-wrap: wrap;
  }

  .header-title {
    flex-basis: 100%;
    width: 100%;
    font-size: 1.5em;
  }

  .header-title a:hover {
    color: white;
  }

  .header-phone svg:hover {
    fill: white;
  }

  .header-social svg:hover {
    fill: white;
  }

  .header-phone {
    flex-basis: 50%;
    z-index: 15;
    margin-left: auto;
    border-left: black 1px solid;
    border-bottom: none;
  }

  .header-phone a {
    width: 20%;
  }

  .header-phone svg {
    width: 40%;
  }

  .header-social {
    flex-basis: 30%;
    border-bottom: none;
    z-index: 6;
  }

  .header-social svg {
    height: 60%;
  }
}

@media screen and (max-width: 425px) { 
  .header-phone {
    font-size: 1.2em;
  }

  .header-phone svg {
    width: 50%;
  }

  .header-social svg {
    height: 55%;
  }
 }

@media screen and (max-width: 391px) { 
  .header-phone {
    font-size: 1em;
  }
 }

@media screen and (max-width: 342px) {
  .header-title {
    font-size: 1.4em;
  }

  .header-phone {
    flex-basis: 48%;
    font-size: .9em;
  }
}
.home-welcome {
    position: relative;
    margin-top: 100px;
    height: 550px;
    font-family: "Quicksand", sans-serif;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0.8)), url(/static/media/girl-frame-hero.634b3a6b.jpg);
    background-size: cover;
}

.home-welcome-content {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1em 4em 1em 2em;
}

.girl-smile-mobile-hero {
    display: none;
}

.welcome-logo {
    width: 23vw;
    height: 23vw;
}

.home-welcome-btn button {
    background-color: orange;
    font-size: 1.2em;
    border: solid 1px white;
    border-radius: 2px;
    color: white;
    width: 240px;
    height: 45px;
    transition: all .2s;
}

.home-welcome-btn button:hover {
    background-color: orange;
    box-shadow: 5px 5px black;
}

.home-welcome-btn-hidden {
    display: none;
}

.mobile-intro {
    display: none;
}

@media screen and (max-width: 1140px) {
    .home-welcome {
        background-position: 20%;
    }
}

@media screen and (max-width: 926px) {
    .home-welcome {
        display: flex;
        flex-direction: column;
        background: none;
        height: auto;
    }

    .home-welcome-content {
        position: static;
        position: initial;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5em;
    }

    .girl-smile-mobile-hero {
        display: block;
        object-fit: cover;
        height: 500px;
        
    }

    .welcome-logo {
        display: none;
    }

    .home-welcome-btn {
        display: none;
    }

    .home-welcome-btn-hidden {
        display: none;
    }

    .home-welcome-btn-hidden button {
        font-size: 1em;
        border: solid 1px white;
        border-radius: 2px;
        color: white;
        background-color: orange;
        padding: .5em 1em;
    }


    .mobile-intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2em 1em 1em 1em;
    }

    .mobile-logo {
        width: 30%;
        margin: auto;
        padding-bottom: 1em;
        border-bottom: 2px solid rgba(199, 12, 199);
    }

    .mobile-intro address {
        margin: 0;
        padding: 1em;
        font-size: 1.3em;
    }
}

@media screen and (max-width: 850px) {
    .mobile-logo {
        width: 40%;
    }
}
@media screen and (max-width: 650px) {
    .mobile-logo {
        width: 50%;
    }
}
@media screen and (max-width: 490px) {
    .mobile-logo {
        width: 65%;
    }
}
.sunnyside-difference-home {
  display: grid;
  grid-template-columns: .5fr 1fr 1fr 1fr 1fr .5fr;
  padding: 2em 0;
  box-shadow: 0 0 5px white;
  margin-bottom: 2em;
}

.sunnyside-difference-home-title {
  grid-column: 1/7;
  text-align: center;
  padding: .5em;
  font-family: 'Shadows Into Light Two', cursive;
  font-size: 3em;
  color: rgb(41, 180, 226);
}

.sunnyside-difference-home-title:after {
  content: "";
  display: block;
  border-bottom: rgb(199, 12, 199) 2px solid;
  width: 25%;
  padding: 10px 0;
  margin: auto;
}

.sunnyside-difference-home-article {
  grid-column: 2/6;
  padding: 0 2em;
}

.sunnyside-difference-home-article p {
  line-height: 40px;
  padding: 1em;
  font-family: "Quicksand", sans-serif;
  font-size: 1.3em;
  text-align: center;
}

.wavy-up {
  margin-top: -200px;
  fill: #12B4EB;
}

.appointment-btn {
  display: none;
}

@media screen and (max-width: 1050px) {
  .sunnyside-difference-home-article {
    grid-column: 1/7;
    padding: 0;
  }
}

@media screen and (max-width: 926px) {
  .sunnyside-difference-home {
    box-shadow: none;
  }

  .sunnyside-difference-home-article p {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .sunnyside-difference-home-title {
    margin: 0;
  }

  .sunnyside-difference-home-article {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .sunnyside-difference-p-1 {
    order: 2;
  }

  .sunnyside-difference-logo {
    order: 1;
  }

  .appointment-btn {
    display: block;
    grid-column: 1/7;
    justify-self: center;
    margin-bottom: 2em;
  }

  .appointment-btn button {
    padding: .5em 1em;
    margin: 1em;
    border: white solid 1px;
    border-radius: 2px;
    font-family: "Quicksand", sans-serif;
  }

  .appointment-btn {
    border: none;
  }
}
.dr-sophie-home {
    margin-top: -50px;
    padding: 30px 100px 40px 100px;
    background-color: #09F;
    color: white;
}

.dr-sophie-home-article {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
}

.dr-sophie-tools {
    grid-column: 1/3;
    height: 28vw;
    width: 28vw;
    border-radius: 50%;
    border: solid 4px white;
    object-fit: cover;
}

.dr-sophie-home-title-p {
    grid-column: 3/6;
    font-size: 1.1em;
    font-family: "Quicksand", sans-serif;

}

.dr-sophie-home-title {
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 3em;
    position: relative;
}

.dr-sophie-home-title:after {
    content: "";
    display: block;
    padding: 8px 0;
    width: 40%;
    border-bottom: 2px solid white;
}

.dr-sophie-home-title span {
    color: #FFA500;
}

.dr-sophie-home-p {
    grid-column: 3/5;
    padding: 1em 0;
    color: white;
}

.dr-sophie-home-button {
    background: transparent;
    padding: .3em .7em;
    font-family: "Quicksand", sans-serif;
    color: white;
    border: white 1px solid;
    border-radius: 2px;
    transition: box-shadow .2s;
}

.dr-sophie-home-button:hover {
    background-color: orange;
    box-shadow: 5px 5px black;
}

@media screen and (max-width: 926px) {

    .dr-sophie-home-article {
        display: flex;
        flex-direction: column;
        padding-top: 3em;
    }

    .dr-sophie-tools {
        height: 48vw;
        width: 48vw;
    }

    .dr-sophie-home-btn-wrapper {
        display: flex;
        justify-content: center;
    }

    .dr-sophie-home .dr-sophie-home-button {
        background-color: #FFA500;
    }
}

@media screen and (max-width:544px) {
    .dr-sophie-home {
        margin-top: -80px;
        padding: 2em;
    }

    .dr-sophie-tools {
        width: 65vw;
        height: 65vw;
        margin-bottom: 2em;
    }
}

#services-btn-wide {
    display: block;
}

#services-btn-small {
    display: none;
}

.smile-services-home {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 100%;
    line-height: 2em;
    margin-top: 4px;
    margin-bottom: 4px;
    background-image: url(/static/media/funky-pattern.2f6c8e2c.png);
    background-attachment: fixed;
    background-size: 70px;
    font-family: "Quicksand", sans-serif;
}

.smile-services-home h2 {
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 3em;
    margin-bottom: 10px;
    padding: 0 0 .2em 0;
    text-align: center;
}

/* LEARN MORE BUTTONS */
.learn-more-btn {
    padding: .2em .7em;
    font-size: 1.1em;
    font-family: "Quicksand", sans-serif;
    color: white;
    border: white 1px solid;
    background: transparent;
    border-radius: 2px;
    transition: box-shadow .2s;
}

.learn-more-btn:hover {
    background: orange;
    box-shadow: 5px 5px black;
}

/* The Smile Club */

.smile-club-home-article {
    grid-column: 4/6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color:rgba(255, 166, 0, .97);
    color: white;
    border-left: white 2px solid;
}

.smile-club-home-header h2 {
    padding: .2em 0 .3em 0;
}

.smile-club-home-article p {
    width: 80%;
    font-size: 1.3em;
    font-family: "Quicksand", sans-serif;
    text-align: center;
}

#learn-more-smile-btn-wrapper {
    padding: 1em 0;
}

.learn-more-smile-btn {
    background: transparent;
    margin: .5em 0;
    padding: .2em .7em;
    font-family: "Quicksand", sans-serif;
    font-size: 1.1em;
    color: white;
    border: white 1px solid;
    border-radius: 2px;
    transition: box-shadow .2s;
}

.learn-more-smile-btn:hover {
    background-color: rgb(199, 12, 199);
    box-shadow: 5px 5px black;
}


/* Our Services */

.services-home-article {
    grid-column: 1/4;
    display: flex;
    height: 100%;
    padding: 2em;
    margin: 0;
    background-color: rgba(199, 12, 199, 0.97);
    color: white;
    border-right: white 2px solid;
}

.services-home-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.services-home-article h2 a{
    color: white;
    text-decoration: none;
    transition: text-shadow .3s;
}

.services-home-article h2 a:hover {
    text-shadow: 3px 3px black;
    text-decoration: none;
    transition: all .2s;
}

.services-home-list {
    margin: 0;
    list-style: square;
}

.services-home-list a {
    color: white;
    text-decoration: none;
}

.services-home-list a:hover {
    color: black;
    text-decoration: none;
}


/* Media Queries */
@media screen and (max-width: 926px){

#services-btn-wide {
    display: none;
}

#services-btn-small {
    display: block;
    padding: 2em 0 1em 0;
}

#learn-more-smile-btn-wrapper {
    padding: 2em 0 1em 0;
}

.smile-services-home {
    display: flex;
    flex-direction: column;
}

.smile-club-home-article, .services-home-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 1em;
    
}

.smile-club-home-article {
    border-left: none;
}

.smile-club-home-article p {
    width: 100%;
}

.services-home-article {
    border-right: none;
    border-bottom: solid 4px white;
}
}
.reviews-home {
    position: relative;
    margin-bottom: 4px;
    height: 120px;
    background-color: rgb(73, 73, 243);
    color: white;
}

.reviews-slideshow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 0;
    list-style: none;
    font-size: 1.5em;
}

.reviews-slideshow span {
   font-family: 'Shadows Into Light Two', cursive;
}

.slide {
    position: absolute;
    opacity: 0;
    z-index: 1;
    transition: opacity .8s;
}

.showing {
    opacity: 1;
    z-index: 2;
}

.google-reviews {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: white;
    font-family: "Quicksand", sans-serif;
}

.google-reviews a {
    color: white;
}

.google-reviews a:hover {
    color: orange;
    text-decoration: none;
}

/* Media Queries */

@media screen and (max-width: 926px) {
    .reviews-home {
        height: 100%;
    }

    .reviews-slideshow {
        padding: 4em 1em;
        font-size: 1.2em;
        margin-bottom: 0;
    }

    .slide {
        padding: 0 .5em;
    }
}
#infection {
  fill: rgb(36, 206, 189);
  height: 40%;
}

#ppe {
  fill: #ff9b85;
  height: 40%;
}

#distancing {
  fill: rgb(18, 165, 214);
  height: 40%;
}

.covid-home {
  grid-column: 1/3;
  padding: 2em 6%;
  text-align: center;
  font-family: "Quicksand", sans-serif;
}

.covid-home h2 {
  text-align: center;
  font-size: 28px;
  padding-bottom: 1em;
}

.covid-home ul {
  display: flex;
  justify-content: space-between;
  padding: 0;
  list-style: none;
}

.covid-home ul li {
  flex: 1 1;
}

.covid-home svg {
  display: block;
  margin: auto;
  width: 80px;
}

@media screen and (max-width: 850px) {
  .covid-home {
    border-radius: 0;
  }

  .covid-home ul {
    flex-direction: column;
  }
}



.why-sunnyside-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    url(/static/media/hallway.8c80611c.jpg) no-repeat center;
  background-size: cover;
}

.why-sunnyside header h1 {
  margin: 0;
  color: white;
}

.why-sunnyside-welcome {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.why-sunnyside-welcome article {
  grid-column: 2/6;
  padding: 1em;
  font-family: "Quicksand", sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
}

.why-sunnyside-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, auto);
}

.why-sunnyside-content article {
  padding: 1em;
  background-color: white;
  font-family: "Quicksand", sans-serif;
}

.why-sunnyside article h2 {
  color: rgb(255, 166, 0);
  margin: 10px 0;
}

.why-specialize {
  grid-column: 1/3;
}

.what-training {
  grid-column: 1/3;
}
.what-inspired {
  grid-column: 1/3;
}

.why-name {
  grid-column: 1/3;
}
.what-different, .what-drew {
  grid-column: 1/3;
}

/* Images */
.kid-welcome {
  grid-column: 3/4;
  grid-row: 1/5;
  align-self: center;
  justify-self: center;
  transform: rotate(-5deg);
}
.our-philosophy-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    url(/static/media/toys.ad333447.jpg) no-repeat center;
  background-size: cover;
  color: white;
}

.our-philosophy-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.our-philosophy-content article {
  grid-column: 1/3;
  padding: 1em 2em 1em 1em;
}

.our-philosophy-content article h3 {
  padding: 0.5em 0;
}

.glasses-cool{
  grid-column: 3/4;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
  transform: rotate(5deg);
}

.girl-mouth-mirror {
  grid-column: 3/4;
  grid-row: 3/6;
  align-self: center;
  justify-self: center;
  transform: rotate(-5deg);
}

@media screen and (max-width: 926px) { 
  .our-philosophy-header {
    background-position: 60% 40%;
  }
 }
.dr-sophie-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    url(/static/media/dr-sophie-hat.d72277fa.jpg);
  background-size: cover;
  background-position: center -120px;
  color: white;
}

.dr-sophie .dr-sophie-content {
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  grid-template-columns: repeat(8, auto);
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-areas:
    "bi bi bi bi bi bi bi me"
    "bi bi bi bi bi bi bi me"
    "ed ed ed ed ed mo mo mo"
    "ed ed ed ed ed mo mo mo";
  margin-top: 1em;
  padding: 1em;
}

.dr-sophie-bio {
  grid-area: bi;
  padding: 0.5em;
}

.dr-sophie h2 {
  color: rgb(255, 166, 0);
  text-align: center;
  margin: 0;
  padding: 0.4em;
}

.dr-sophie-education {
  grid-area: ed;
}

.dr-sophie-memberships {
  grid-area: me;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  text-align: center;
}

.dr-sophie-more {
  grid-area: mo;
}

.dr-sophie ul li {
  padding: 0.5em 0;
}

.dr-sophie-memberships .memberships-list {
  list-style: none;
  padding: 0;
  font-size: 1.1em;
}

.dr-sophie-memberships .memberships-list a {
  color: black;
}

.dr-sophie-memberships .memberships-list a:hover {
  color: #C70CC7;
}

.memberships-list img {
  display: block;
  margin: auto;
  padding-bottom: 0.4em;
  height: 50px;
}

#dr-sophie-pose {
  width: 40%;
  max-width: 300px;
  float: left;
  margin: .7em 1em .5em 0;
  padding: 3px;
  box-shadow: 0 0 2px grey;
  grid-area: dr;
}

@media screen and (max-width: 926px) {
  .dr-sophie-header {
    background-position: right;

  }

  #dr-sophie-pose {
    float: none;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  .dr-sophie .dr-sophie-content {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .dr-sophie .dr-sophie-content article {
    padding: 0;
  }

  .dr-sophie-bio {
    padding: 0;
    text-align: center;
  }

  .memberships-list img {
    height: 80px;
  }
}

#woman-smile {
  padding: 2em;
}

.meet-the-team h3 {
  margin: 0.5em;
}

.meet-the-team h4 {
  margin: 0.5em;
}

.meet-the-team-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/static/media/exterior-wide.315d93a3.jpg) no-repeat center;
  color: white;
  background-size: cover;
}

.meet-the-team .employees {
  margin-top: 1em;
}

.employees article {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1em 0;
  margin: 2em 0;
  justify-items: center;
}

.employees article header {
  grid-column: 1/3;
  text-align: center;  
}

.employees img {
  width: 15vw;
  height: 15vw;
  padding: 3px;
  object-fit: cover;
  border: #C731C7 solid 3px;
  border-radius: 50%;
  box-shadow: 2px 2px 5px grey;
  grid-column: 1/3;
}

.employees p {
  grid-column: 3/7;
  grid-row: 1/3;
  align-self: center;
}

@media screen and (max-width: 926px) { 
  .employees article {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
  }

  .employees article h3 {
    margin-left: 0;
    margin-right: 0;
  }

  .employees img {
    width: 40vw;
    height: 40vw;
    margin: .7em 0;
  }

  .olivia-header {
    padding: 0;
  }
 }

.office-tour-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center url(/static/media/toy-holder.35b0e3b2.jpg);
  color: white;
}

.office-tour .office-tour-content {
  margin-top: 4em;
}

.office-tour img {
  height: 600px;
  object-fit: cover;
}

.office-tour p {
  text-align: center;
  margin: 0;
}

.rec.rec-arrow {
  background-color: rgba(18, 165, 214);
  color: white;
}

.rec.rec-arrow:hover {
  background-color: rgba(18, 165, 214) !important;
}

.rec.rec-arrow:active {
  background-color: rgba(18, 165, 214) !important;
}

.rec.rec-arrow:focus {
  background-color: rgba(18, 165, 214) !important;
}

.rec-pagination button {
  background-color: rgba(18, 165, 214);
  height: 10px;
  width: 10px;
}

.rec-pagination .rec-dot_active {
  box-shadow: none;
}
.rec-pagination .rec-dot_active:focus {
  box-shadow: none;
}

@media screen and (max-width: 991px) {
  .office-tour .office-tour-content {
    margin-top: 1em;
    padding-top: 1em;
  }

  .office-tour img {
    height: 300px;
    width: 100%;
  }

  .rec.rec-arrow {
    display: none;
  }

  .rec-carousel-wrapper .rec-pagination button {
    height: 10px !important;
    width: 10px !important;
  }
}

.dental-services-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat url(/static/media/irrigator.f2b8b63e.jpg);
  background-position: center 15%;
  color: white;
  text-align: center;
}

.dental-services-content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1em;
}

.dental-services-content header {
  color: rgb(255, 166, 0);
  text-align: center;
  padding: 1em 0;
}

.services-list-menu {
  text-align: center;
  padding: 0;
  font-size: 1.1em;
}

.services-list-menu li {
  display: inline-block;
}

.services-list-menu a {
  color: rgb(53, 51, 51) !important;
}

.services-list-menu span {
  color: #C731C7;
}

.services-list-menu a:hover {
  color: #C731C7 !important;
}

.dental-services-content article {
  padding: 1em;
  margin: 0.5em;
}

.services-description {
  display: flex;
  align-items: center;
}

.dental-services-content p {
  width: 70%;
}

.dental-services-content svg {
  width: 20%;
}


@media screen and (max-width: 926px) { 
  .dental-services-header {
    background-position: 70% 40%;
  }

  .services-list-menu {
    display: none;
  }

  .services-description {
    flex-direction: column;
  }

  .dental-services-content header {
    padding: 0;
  }

  .dental-services-content article {
    margin: 0;
  }

  .dental-services-content p {
    width: 100%;
  }

  .dental-services .anesthesia-icon {
    height: 20%;
    margin: 1em 0;
  }
 }

.emergency-care-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat 0 50% url(/static/media/dental-chair.0718e773.jpg);
  background-size: 100%;
  color: white;
  text-align: center;
}

.emergency-care-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.emergency-care-content article {
  grid-column: 1/3;
  padding: 0 2em 1.5em 1em;
}

.emergency-care-content article h2 {
  margin: 10px 0;
  color: #FFA500;
}

.emergency-care-content article h3 {
  color: #12B4EB;
}

.emergency-care-content li {
  padding: 0.5em;
}

.soccer {
  grid-column: 3/4;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  transform: rotate(5deg);
}

.football {
  grid-column: 3/4;
  grid-row: 2/3;
  align-self: center;
  justify-self: center;
  transform: rotate(-5deg);
}

@media screen and (max-width: 926px) {

  .emergency-care-header {
    background-size: cover;
  }
}

.printables-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat 30% 70%/100% url(/static/media/kid-reading.6a58f507.jpg);
  color: white;
  text-align: center;
}

.printables .printables-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
}

.printables .printables-content button {
  padding: 1.5em;
  margin: 1em;
  box-shadow: 0 0 2px grey;
  background-color: white;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  transition: transform .5s;
}

.printables-content h4 {
  text-align: center;
  margin: 5px 0 0 0;
  color: #12B4EB;
}

.printables-content svg {
  fill: #12B4EB
}

.printables button:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 926px) {

  .printables-header {
    background-size: cover;
  }

}
.new-patients-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url(/static/media/new-patients.b3593585.jpg);
  color: white;
  text-align: center;
}

.new-patients-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1em;
  margin-bottom: 1em;
}

.new-patients-buttons {
  grid-column: 1/4;
  text-align: center;
  margin: 1em;
}

.new-patients-buttons button {
  padding: .3em .7em;
  margin: 1em;
  font-size: 1.2em;
  color: white;
  background-color: orange;
  border: white solid 1px;
  border-radius: 2px;
  transition: box-shadow .3s;
}

.new-patients-buttons button:hover {
  box-shadow: 5px 5px black;
}

.new-patients-content article {
  grid-column: 1/3;
}

.new-patients-content li {
  padding: 0.2em;
  font-size: 1.2em;
}

.kid-toothbrush-smile {
  grid-column: 3/4;
  grid-row: 2/3;
  justify-self: center;
  align-self: center;
  margin-top: 200px;
  transform: rotate(5deg);
}

@media screen and (max-width: 926px) {

  .new-patients-header {
    background-size: cover;
    background-position: 80%;
  }

.girl-rabbit {
  margin-top: 0;
}

.toothbrush {
  display: none;
}
}
.insurance-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url(/static/media/insurance.d1d32951.jpg);
  color: white;
  text-align: center;
}

.insurance-content {
  display: grid;
  grid-gap: 2em;
  gap: 2em;
  grid-template-areas:
    "ov ov ov ov pp"
    "on on on on pp"
    "if if if if if";
  padding-top: 2em;
  margin-top: 1em;
}

.insurance-content h2 {
  text-align: center;
}

.insurance li {
  padding: 0.5em;
}

.overview {
  grid-area: ov;
}

.our-payment-policy {
  grid-area: pp;
}

.out-of-network {
  grid-area: on;
}

.insurance-footer {
  grid-area: if;
}

.overview-smile-club-btn-wrapper {
  text-align: center;
  margin: 1em 0;
}

.overview-smile-club-btn-wrapper button {
  justify-items: center;
  margin: 15px;
  padding: .4em .8em;
  border-radius: 2px;
  border: white 1px solid;
  border-radius: 2px;
  background-color: orange;
  color: white;
  font-size: 1.2em;
  transition: all .2s;
}

.overview button:hover {
  box-shadow: 5px 5px black;
}

.our-payment-policy p {
  padding-top: .4em;
  margin: auto;
}

.predetermination-list li{
  font-size: .9em;
}

@media screen and (max-width: 926px) { 
  .insurance-header {
    background-size: cover;
    background-position: 80%;
  }
 }
.smile-club-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url(/static/media/baby-smile.4426a84d.jpg);
  color: white;
}

.smile-club-welcome article {
  grid-column: 2/6;
  padding: 1em;
  font-family: "Quicksand", sans-serif;
  font-size: 1.1em;
  text-align: center;
}

.smile-club-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.smile-club-content article{
  grid-column: 1/3;
  padding: 1em 1em 0 1em;
}

/* Images */
.smile-plushie {
  grid-column: 3/4;
  grid-row: 1/4;
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: center;
  transform: rotate(-5deg);
}

@media screen and (max-width: 926px) {

  .smile-club-header {
    background-size: cover;
    background-position: 24%;
  }
  
}

.faq-header {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url(/static/media/faq.c1bea9a8.jpg);
  color: white;
  text-align: center;
}

.faq-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.faq-content article {
  grid-column: 1/3;
  padding: 1em 2em 1em 1em;
}

.dr-sophie-funny {
  grid-column: 3/4;
  grid-row: 1/5;
  object-position: top;
  align-self: center;
  justify-self: center;
  transform: rotate(5deg);
}

.faq-footer {
  padding: 1em 0;
  margin: 1em 5em;
  padding: 0 2em 1em 1em;
  border-radius: 5px;
  font-weight: bold;
  background-color: white;
}

.faq-footer a {
  color: #C731C7;
}

.faq-footer a:hover {
  color: orange;
  text-decoration: none;
}

@media screen and (max-width: 926px) { 

  .faq-header {
    background-size: cover;
  }

  .faq-footer {
    margin: 0;
  }
 }
.covid-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url(/static/media/covid.f7297dce.jpg);
  color: white;
  text-align: center;
}

.covid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1em;
}

.covid-content article {
  grid-column: 1/3;
  padding: 0 2em 1em 1em;
}

.covid-content ul {
  font-size: 1.1em;
}

.covid-content li {
  padding: 0.2em;
}

.covid-survey-btn-wrapper {
  grid-column: 2/3;
  padding: 0.5em;
  margin-bottom: 5px;
  text-align: center;
}

.covid-survey-btn-wrapper a {
  color: white;
}

.covid-content .before-visit {
  grid-column: 1/4;
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}

.before-visit header {
  padding: 1em 0;
}

.covid-btns {
  display: flex;
  justify-content: space-around;
  margin: 1.5em;
}

.covid-btns button {
  height: 50px;
  width: 200px;
  font-size: 1.1em;
  color: white;
  background-color: orange;
  border-radius: 2px;
  border: none;
  transition: box-shadow .2s;
}

.covid-btns button:hover {
  box-shadow: 5px 5px 1px black;
}

.mask {
  grid-column: 3/4;
  grid-row: 4/6;
  align-self: center;
  justify-self: center;
  transform: rotate(5deg);
}

@media screen and (max-width: 926px) { 

  .covid button {
    margin: 1em;
  }

  .covid-survey-btn {
    padding: .2em;
  }

  .covid-btns {
    flex-direction: column;
  }

 }
.contact-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat 100% 80%/100% url(/static/media/red-phone.3aa3b213.jpg);
  color: white;
}

.contact .contact-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4em;
  gap: 4em;
  justify-items: center;
  font-size: 1.1em;
}

.contact-content h2 {
  color: rgb(49, 165, 204);
  text-align: center;
}

.contact-content address {
  font-style: normal;
  text-align: center;
  font-size: 1.1em;
}

.contact-content ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

#contact-list a {
  color: #212529;
}

#contact-list a:hover {
  color: #C731C7;
}

.contact-hours-title {
  text-align: center;
}

.contact-hours {
  text-align: center;
}

/* Contact Form */
#first-name {
  width: 48%;
}

#last-name {
  width: 48%;
}

#email {
  width: 100%;
}

#phone {
  width: 100%;
}

.contact-form {
  grid-column: 2/3;
  grid-row: 1/3;
  width: 85%;
}

.name-form {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.textarea {
  width: 100%;
}

#contact-submit-btn {
  padding: .3em 2em;
  color: white;
  background-color: orange;
  border: white solid 1px;
  border-radius: 2px;
  transition: box-shadow .2s;
}

#contact-submit-btn:hover {
  box-shadow: 5px 5px black;
}

.error-message {
  color: red;
}

.submitted-message {
  grid-column: 2/3;
  font-size: 1.1em;
}

@media screen and (max-width: 926px) {

  .contact-header {
    background-size: cover;
    background-position: 10%;
  }

  .contact .contact-content {
    display: flex;
    flex-direction: column;
  }

  #contact-list {
    padding: 0;
  }

  #contact-submit-btn {
    margin-top: .5em;
  }

  .contact .hours-parking {
    padding: 1em;
  }

  .contact-form {
    margin-top: 1em;
    padding: 0 1em;
    width: 100%;
  }
}

.footer {
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(199, 12, 199, 0.8);
  color: white;
  font-family: "Quicksand", sans-serif;
}

.footer section {
  padding: 1em;
}

.footer h4 {
  margin: 5px;
  font-size: 1.3em;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: orange;
  text-decoration: none;
}

.footer a svg:hover {
  fill: orange;
}

.address svg {
  height: 55px;
}

.address address {
  font-style: normal;
  font-family: "Quicksand", sans-serif;
}

.phone {
  display: flex;
  font-size: .9em;
}

.phone div {
  margin: 0 .5em;
}

.phone svg {
  width: 33px;
  height: 33px;
  fill: white;
}

.fax p {
  color: inherit;
}

.footer .contact div {
  padding: 0 0.2em;
}

.contact svg {
  height: 30px;
  margin: 5px 10px;
  fill: white;
}

@media screen and (max-width: 1023px) {
  .footer {
    display: flex;
    flex-direction: column;
  }

  .phone {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@font-face {
  font-family: 'scream-it-loud';
  src: url(/static/media/sream-it-loud.f77c3fbb.ttf);
}

:root {
  --orange: #FFA500;
  --purple: #C731C7;
}

p {
  font-size: 1.2em;
  margin-bottom: 0 !important;
  padding: 0.2em;
}

ul {
  font-size: 1.2em;
}

a {
  text-decoration: none;
}

button {
  font-weight: 500;
}

.subpage-content {
  margin: 0 5em;
}

.subpage-content h2 {
  color: rgb(255, 166, 0);
}

.subpage-content h3 {
  color: #12B4EB;
}

.subpage-content a {
  text-decoration: none;
  font-family: "Quicksand", sans-serif;
  color: #C731C7;
}

.subpage-content a:hover {
  text-decoration: none !important;
  color: orange;
}

.subpage {
  padding-bottom: 1em;
  font-family: "Quicksand", sans-serif;
}

.welcome {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1.1em 0 1em 0;
}

.welcome article {
  grid-column: 2/6;
  padding: 1em;
  font-family: "Quicksand", sans-serif;
  font-size: 1.1em;
  text-align: center;
}

.welcome a {
  color: #C731C7;
}

.welcome a:hover {
  color: orange;
  text-decoration: none;
}


.subpage-header {
  height: 400px;
  margin-top: 100px;
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Quicksand", sans-serif;

}

.subpage header h1 {
  font-size: 3.5em;
  text-align: center;
  padding: 0.5em;
}

.subpage-header p {
  padding: 0 7em;
  font-size: 1.4em;
}

/* Side Images for Subpages */

.side-img {
  height: 21vw;
  width: 21vw;
  object-fit: cover;
  border-radius: 18px;
  padding: 3px;
  border: #12B4EB 3px solid;
  box-shadow: 1px 1px 4px grey;
}

/* Layout */


/* Media Queries */
@media screen and (max-width: 926px) {
  .content button {
    font-size: 1.2em;
    background-color: orange;
    color: white;
  }

  .welcome {
    display: flex;
  }

  .subpage-content {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .subpage-content h2 {
    text-align: center;
    
  }

  .subpage-content h3 {
    text-align: center;
    font-size: 1.6em;
  }

  .subpage-content article {
    padding: 1em;
  }

  .side-img {
    width: 30vw;
    height: 30vw;
    margin: 1em 0;
  }
}

@media screen and (max-width: 650px) {
  .side-img {
    width: 50vw;
    height: 50vw;
  }
}
