.emergency-care-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat 0 50% url("../../../../assets/banners/dental-chair.jpg");
  background-size: 100%;
  color: white;
  text-align: center;
}

.emergency-care-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.emergency-care-content article {
  grid-column: 1/3;
  padding: 0 2em 1.5em 1em;
}

.emergency-care-content article h2 {
  margin: 10px 0;
  color: #FFA500;
}

.emergency-care-content article h3 {
  color: #12B4EB;
}

.emergency-care-content li {
  padding: 0.5em;
}

.soccer {
  grid-column: 3/4;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  transform: rotate(5deg);
}

.football {
  grid-column: 3/4;
  grid-row: 2/3;
  align-self: center;
  justify-self: center;
  transform: rotate(-5deg);
}

@media screen and (max-width: 926px) {

  .emergency-care-header {
    background-size: cover;
  }
}
