.our-philosophy-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    url("../../../../assets/banners/toys.jpg") no-repeat center;
  background-size: cover;
  color: white;
}

.our-philosophy-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.our-philosophy-content article {
  grid-column: 1/3;
  padding: 1em 2em 1em 1em;
}

.our-philosophy-content article h3 {
  padding: 0.5em 0;
}

.glasses-cool{
  grid-column: 3/4;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
  transform: rotate(5deg);
}

.girl-mouth-mirror {
  grid-column: 3/4;
  grid-row: 3/6;
  align-self: center;
  justify-self: center;
  transform: rotate(-5deg);
}

@media screen and (max-width: 926px) { 
  .our-philosophy-header {
    background-position: 60% 40%;
  }
 }