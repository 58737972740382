.office-tour-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center url("../../../../assets/banners/toy-holder.jpg");
  color: white;
}

.office-tour .office-tour-content {
  margin-top: 4em;
}

.office-tour img {
  height: 600px;
  object-fit: cover;
}

.office-tour p {
  text-align: center;
  margin: 0;
}

.rec.rec-arrow {
  background-color: rgba(18, 165, 214);
  color: white;
}

.rec.rec-arrow:hover {
  background-color: rgba(18, 165, 214) !important;
}

.rec.rec-arrow:active {
  background-color: rgba(18, 165, 214) !important;
}

.rec.rec-arrow:focus {
  background-color: rgba(18, 165, 214) !important;
}

.rec-pagination button {
  background-color: rgba(18, 165, 214);
  height: 10px;
  width: 10px;
}

.rec-pagination .rec-dot_active {
  box-shadow: none;
}
.rec-pagination .rec-dot_active:focus {
  box-shadow: none;
}

@media screen and (max-width: 991px) {
  .office-tour .office-tour-content {
    margin-top: 1em;
    padding-top: 1em;
  }

  .office-tour img {
    height: 300px;
    width: 100%;
  }

  .rec.rec-arrow {
    display: none;
  }

  .rec-carousel-wrapper .rec-pagination button {
    height: 10px !important;
    width: 10px !important;
  }
}
