.smile-club-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url("../../../../assets/banners/baby-smile.jpg");
  color: white;
}

.smile-club-welcome article {
  grid-column: 2/6;
  padding: 1em;
  font-family: "Quicksand", sans-serif;
  font-size: 1.1em;
  text-align: center;
}

.smile-club-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.smile-club-content article{
  grid-column: 1/3;
  padding: 1em 1em 0 1em;
}

/* Images */
.smile-plushie {
  grid-column: 3/4;
  grid-row: 1/4;
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: center;
  transform: rotate(-5deg);
}

@media screen and (max-width: 926px) {

  .smile-club-header {
    background-size: cover;
    background-position: 24%;
  }
  
}
