.contact-header {
  font-family: Bentham;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat 100% 80%/100% url("../../../assets/banners/red-phone.jpg");
  color: white;
}

.contact .contact-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4em;
  justify-items: center;
  font-size: 1.1em;
}

.contact-content h2 {
  color: rgb(49, 165, 204);
  text-align: center;
}

.contact-content address {
  font-style: normal;
  text-align: center;
  font-size: 1.1em;
}

.contact-content ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

#contact-list a {
  color: #212529;
}

#contact-list a:hover {
  color: #C731C7;
}

.contact-hours-title {
  text-align: center;
}

.contact-hours {
  text-align: center;
}

/* Contact Form */
#first-name {
  width: 48%;
}

#last-name {
  width: 48%;
}

#email {
  width: 100%;
}

#phone {
  width: 100%;
}

.contact-form {
  grid-column: 2/3;
  grid-row: 1/3;
  width: 85%;
}

.name-form {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.textarea {
  width: 100%;
}

#contact-submit-btn {
  padding: .3em 2em;
  color: white;
  background-color: orange;
  border: white solid 1px;
  border-radius: 2px;
  transition: box-shadow .2s;
}

#contact-submit-btn:hover {
  box-shadow: 5px 5px black;
}

.error-message {
  color: red;
}

.submitted-message {
  grid-column: 2/3;
  font-size: 1.1em;
}

@media screen and (max-width: 926px) {

  .contact-header {
    background-size: cover;
    background-position: 10%;
  }

  .contact .contact-content {
    display: flex;
    flex-direction: column;
  }

  #contact-list {
    padding: 0;
  }

  #contact-submit-btn {
    margin-top: .5em;
  }

  .contact .hours-parking {
    padding: 1em;
  }

  .contact-form {
    margin-top: 1em;
    padding: 0 1em;
    width: 100%;
  }
}
