.dr-sophie-home {
    margin-top: -50px;
    padding: 30px 100px 40px 100px;
    background-color: #09F;
    color: white;
}

.dr-sophie-home-article {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
}

.dr-sophie-tools {
    grid-column: 1/3;
    height: 28vw;
    width: 28vw;
    border-radius: 50%;
    border: solid 4px white;
    object-fit: cover;
}

.dr-sophie-home-title-p {
    grid-column: 3/6;
    font-size: 1.1em;
    font-family: "Quicksand", sans-serif;

}

.dr-sophie-home-title {
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 3em;
    position: relative;
}

.dr-sophie-home-title:after {
    content: "";
    display: block;
    padding: 8px 0;
    width: 40%;
    border-bottom: 2px solid white;
}

.dr-sophie-home-title span {
    color: #FFA500;
}

.dr-sophie-home-p {
    grid-column: 3/5;
    padding: 1em 0;
    color: white;
}

.dr-sophie-home-button {
    background: transparent;
    padding: .3em .7em;
    font-family: "Quicksand", sans-serif;
    color: white;
    border: white 1px solid;
    border-radius: 2px;
    transition: box-shadow .2s;
}

.dr-sophie-home-button:hover {
    background-color: orange;
    box-shadow: 5px 5px black;
}

@media screen and (max-width: 926px) {

    .dr-sophie-home-article {
        display: flex;
        flex-direction: column;
        padding-top: 3em;
    }

    .dr-sophie-tools {
        height: 48vw;
        width: 48vw;
    }

    .dr-sophie-home-btn-wrapper {
        display: flex;
        justify-content: center;
    }

    .dr-sophie-home .dr-sophie-home-button {
        background-color: #FFA500;
    }
}

@media screen and (max-width:544px) {
    .dr-sophie-home {
        margin-top: -80px;
        padding: 2em;
    }

    .dr-sophie-tools {
        width: 65vw;
        height: 65vw;
        margin-bottom: 2em;
    }
}
