.insurance-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat center/100% url("../../../../assets/banners/insurance.jpg");
  color: white;
  text-align: center;
}

.insurance-content {
  display: grid;
  gap: 2em;
  grid-template-areas:
    "ov ov ov ov pp"
    "on on on on pp"
    "if if if if if";
  padding-top: 2em;
  margin-top: 1em;
}

.insurance-content h2 {
  text-align: center;
}

.insurance li {
  padding: 0.5em;
}

.overview {
  grid-area: ov;
}

.our-payment-policy {
  grid-area: pp;
}

.out-of-network {
  grid-area: on;
}

.insurance-footer {
  grid-area: if;
}

.overview-smile-club-btn-wrapper {
  text-align: center;
  margin: 1em 0;
}

.overview-smile-club-btn-wrapper button {
  justify-items: center;
  margin: 15px;
  padding: .4em .8em;
  border-radius: 2px;
  border: white 1px solid;
  border-radius: 2px;
  background-color: orange;
  color: white;
  font-size: 1.2em;
  transition: all .2s;
}

.overview button:hover {
  box-shadow: 5px 5px black;
}

.our-payment-policy p {
  padding-top: .4em;
  margin: auto;
}

.predetermination-list li{
  font-size: .9em;
}

@media screen and (max-width: 926px) { 
  .insurance-header {
    background-size: cover;
    background-position: 80%;
  }
 }