.main-header {
  z-index: 10;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  background-color: white;
  border-bottom: black 1px solid;
  box-shadow: 0 0 2px grey;
  max-width: 1500px;
}

.header-contact {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  color: white;
}

.header-title {
  flex-basis: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 5px 0;
  font-size: 1.9em;
  font-family: 'scream-it-loud';
  background-color: orange;
  text-align: center;
  border-right: black 1px solid;
  border-bottom: black 1px solid;
}

.header-title a {
  color: white;
}

.header-title a:hover {
  color: rgb(199, 12, 199);
  text-decoration: none;
}

.header-phone {
  flex-basis: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  margin: 0;
  padding: 6px 0;
  font-size: 1.3em;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  background-color: rgb(18, 180, 235);
  border-right: black 1px solid;
  border-bottom: black 1px solid;
}

.header-phone a {
  width: 25%;
}

.header-phone svg {
  width: 32%;
  fill: white;
}

.header-phone svg:hover {
  fill: rgb(199, 12, 199);
}

.header-social {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  background-color: rgb(199, 12, 199);
  border-bottom: black 1px solid;
  text-align: center;
}

.header-social a {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-social svg {
  height: 60%;
  fill: white;
  background-color: rgb(199, 12, 199);
  border-radius: 30%;
  padding: 3px;
  transition: transform .2s;
}

.header-social svg:hover {
  fill: orange;
}

.nav-logo {
  height: 90px;
}

.navbar {
  margin: 0 7em;
}

.navbar a {
  font-family: 'Handlee', cursive !important;
  font-weight: 600;
  color: black !important;
}

.custom-background {
  background-color: white;
}

@media screen and (max-width: 991px) {
  .navbar {
    margin: 0;
  }

  .main-header {
    height: 101px;
  }

  .navbar-light .navbar-toggler {
    border: none;
  }

  #responsive-navbar-nav {
    z-index: 10;
    margin-top: 7px;
    border: solid 1px black;
    background-color: white;
  }

  .custom-background {
    padding: 0 !important;
  }

  #mobile-button {
    width: 17%;
  }

  .header-contact {
    flex-wrap: wrap;
  }

  .header-title {
    flex-basis: 100%;
    width: 100%;
    font-size: 1.5em;
  }

  .header-title a:hover {
    color: white;
  }

  .header-phone svg:hover {
    fill: white;
  }

  .header-social svg:hover {
    fill: white;
  }

  .header-phone {
    flex-basis: 50%;
    z-index: 15;
    margin-left: auto;
    border-left: black 1px solid;
    border-bottom: none;
  }

  .header-phone a {
    width: 20%;
  }

  .header-phone svg {
    width: 40%;
  }

  .header-social {
    flex-basis: 30%;
    border-bottom: none;
    z-index: 6;
  }

  .header-social svg {
    height: 60%;
  }
}

@media screen and (max-width: 425px) { 
  .header-phone {
    font-size: 1.2em;
  }

  .header-phone svg {
    width: 50%;
  }

  .header-social svg {
    height: 55%;
  }
 }

@media screen and (max-width: 391px) { 
  .header-phone {
    font-size: 1em;
  }
 }

@media screen and (max-width: 342px) {
  .header-title {
    font-size: 1.4em;
  }

  .header-phone {
    flex-basis: 48%;
    font-size: .9em;
  }
}