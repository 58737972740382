.footer {
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(199, 12, 199, 0.8);
  color: white;
  font-family: "Quicksand", sans-serif;
}

.footer section {
  padding: 1em;
}

.footer h4 {
  margin: 5px;
  font-size: 1.3em;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: orange;
  text-decoration: none;
}

.footer a svg:hover {
  fill: orange;
}

.address svg {
  height: 55px;
}

.address address {
  font-style: normal;
  font-family: "Quicksand", sans-serif;
}

.phone {
  display: flex;
  font-size: .9em;
}

.phone div {
  margin: 0 .5em;
}

.phone svg {
  width: 33px;
  height: 33px;
  fill: white;
}

.fax p {
  color: inherit;
}

.footer .contact div {
  padding: 0 0.2em;
}

.contact svg {
  height: 30px;
  margin: 5px 10px;
  fill: white;
}

@media screen and (max-width: 1023px) {
  .footer {
    display: flex;
    flex-direction: column;
  }

  .phone {
    flex-wrap: wrap;
    justify-content: center;
  }
}
