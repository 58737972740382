#infection {
  fill: rgb(36, 206, 189);
  height: 40%;
}

#ppe {
  fill: #ff9b85;
  height: 40%;
}

#distancing {
  fill: rgb(18, 165, 214);
  height: 40%;
}

.covid-home {
  grid-column: 1/3;
  padding: 2em 6%;
  text-align: center;
  font-family: "Quicksand", sans-serif;
}

.covid-home h2 {
  text-align: center;
  font-size: 28px;
  padding-bottom: 1em;
}

.covid-home ul {
  display: flex;
  justify-content: space-between;
  padding: 0;
  list-style: none;
}

.covid-home ul li {
  flex: 1 1 0px;
}

.covid-home svg {
  display: block;
  margin: auto;
  width: 80px;
}

@media screen and (max-width: 850px) {
  .covid-home {
    border-radius: 0;
  }

  .covid-home ul {
    flex-direction: column;
  }
}
