.dental-services-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    no-repeat url("../../../../assets/banners/irrigator.jpg");
  background-position: center 15%;
  color: white;
  text-align: center;
}

.dental-services-content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1em;
}

.dental-services-content header {
  color: rgb(255, 166, 0);
  text-align: center;
  padding: 1em 0;
}

.services-list-menu {
  text-align: center;
  padding: 0;
  font-size: 1.1em;
}

.services-list-menu li {
  display: inline-block;
}

.services-list-menu a {
  color: rgb(53, 51, 51) !important;
}

.services-list-menu span {
  color: #C731C7;
}

.services-list-menu a:hover {
  color: #C731C7 !important;
}

.dental-services-content article {
  padding: 1em;
  margin: 0.5em;
}

.services-description {
  display: flex;
  align-items: center;
}

.dental-services-content p {
  width: 70%;
}

.dental-services-content svg {
  width: 20%;
}


@media screen and (max-width: 926px) { 
  .dental-services-header {
    background-position: 70% 40%;
  }

  .services-list-menu {
    display: none;
  }

  .services-description {
    flex-direction: column;
  }

  .dental-services-content header {
    padding: 0;
  }

  .dental-services-content article {
    margin: 0;
  }

  .dental-services-content p {
    width: 100%;
  }

  .dental-services .anesthesia-icon {
    height: 20%;
    margin: 1em 0;
  }
 }
