.why-sunnyside-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    url("../../../../assets/banners/hallway.jpg") no-repeat center;
  background-size: cover;
}

.why-sunnyside header h1 {
  margin: 0;
  color: white;
}

.why-sunnyside-welcome {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.why-sunnyside-welcome article {
  grid-column: 2/6;
  padding: 1em;
  font-family: "Quicksand", sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
}

.why-sunnyside-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, auto);
}

.why-sunnyside-content article {
  padding: 1em;
  background-color: white;
  font-family: "Quicksand", sans-serif;
}

.why-sunnyside article h2 {
  color: rgb(255, 166, 0);
  margin: 10px 0;
}

.why-specialize {
  grid-column: 1/3;
}

.what-training {
  grid-column: 1/3;
}
.what-inspired {
  grid-column: 1/3;
}

.why-name {
  grid-column: 1/3;
}
.what-different, .what-drew {
  grid-column: 1/3;
}

/* Images */
.kid-welcome {
  grid-column: 3/4;
  grid-row: 1/5;
  align-self: center;
  justify-self: center;
  transform: rotate(-5deg);
}