.home-welcome {
    position: relative;
    margin-top: 100px;
    height: 550px;
    font-family: "Quicksand", sans-serif;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0.8)), url("../../../../assets/girl-frame-hero.jpg");
    background-size: cover;
}

.home-welcome-content {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1em 4em 1em 2em;
}

.girl-smile-mobile-hero {
    display: none;
}

.welcome-logo {
    width: 23vw;
    height: 23vw;
}

.home-welcome-btn button {
    background-color: orange;
    font-size: 1.2em;
    border: solid 1px white;
    border-radius: 2px;
    color: white;
    width: 240px;
    height: 45px;
    transition: all .2s;
}

.home-welcome-btn button:hover {
    background-color: orange;
    box-shadow: 5px 5px black;
}

.home-welcome-btn-hidden {
    display: none;
}

.mobile-intro {
    display: none;
}

@media screen and (max-width: 1140px) {
    .home-welcome {
        background-position: 20%;
    }
}

@media screen and (max-width: 926px) {
    .home-welcome {
        display: flex;
        flex-direction: column;
        background: none;
        height: auto;
    }

    .home-welcome-content {
        position: initial;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5em;
    }

    .girl-smile-mobile-hero {
        display: block;
        object-fit: cover;
        height: 500px;
        
    }

    .welcome-logo {
        display: none;
    }

    .home-welcome-btn {
        display: none;
    }

    .home-welcome-btn-hidden {
        display: none;
    }

    .home-welcome-btn-hidden button {
        font-size: 1em;
        border: solid 1px white;
        border-radius: 2px;
        color: white;
        background-color: orange;
        padding: .5em 1em;
    }


    .mobile-intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2em 1em 1em 1em;
    }

    .mobile-logo {
        width: 30%;
        margin: auto;
        padding-bottom: 1em;
        border-bottom: 2px solid rgba(199, 12, 199);
    }

    .mobile-intro address {
        margin: 0;
        padding: 1em;
        font-size: 1.3em;
    }
}

@media screen and (max-width: 850px) {
    .mobile-logo {
        width: 40%;
    }
}
@media screen and (max-width: 650px) {
    .mobile-logo {
        width: 50%;
    }
}
@media screen and (max-width: 490px) {
    .mobile-logo {
        width: 65%;
    }
}