.dr-sophie-header {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.6)),
    url("../../../../assets/dr-sophie/dr-sophie-hat.jpg");
  background-size: cover;
  background-position: center -120px;
  color: white;
}

.dr-sophie .dr-sophie-content {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(8, auto);
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-areas:
    "bi bi bi bi bi bi bi me"
    "bi bi bi bi bi bi bi me"
    "ed ed ed ed ed mo mo mo"
    "ed ed ed ed ed mo mo mo";
  margin-top: 1em;
  padding: 1em;
}

.dr-sophie-bio {
  grid-area: bi;
  padding: 0.5em;
}

.dr-sophie h2 {
  color: rgb(255, 166, 0);
  text-align: center;
  margin: 0;
  padding: 0.4em;
}

.dr-sophie-education {
  grid-area: ed;
}

.dr-sophie-memberships {
  grid-area: me;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  text-align: center;
}

.dr-sophie-more {
  grid-area: mo;
}

.dr-sophie ul li {
  padding: 0.5em 0;
}

.dr-sophie-memberships .memberships-list {
  list-style: none;
  padding: 0;
  font-size: 1.1em;
}

.dr-sophie-memberships .memberships-list a {
  color: black;
}

.dr-sophie-memberships .memberships-list a:hover {
  color: #C70CC7;
}

.memberships-list img {
  display: block;
  margin: auto;
  padding-bottom: 0.4em;
  height: 50px;
}

#dr-sophie-pose {
  width: 40%;
  max-width: 300px;
  float: left;
  margin: .7em 1em .5em 0;
  padding: 3px;
  box-shadow: 0 0 2px grey;
  grid-area: dr;
}

@media screen and (max-width: 926px) {
  .dr-sophie-header {
    background-position: right;

  }

  #dr-sophie-pose {
    float: none;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  .dr-sophie .dr-sophie-content {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .dr-sophie .dr-sophie-content article {
    padding: 0;
  }

  .dr-sophie-bio {
    padding: 0;
    text-align: center;
  }

  .memberships-list img {
    height: 80px;
  }
}
