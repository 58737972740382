@font-face {
  font-family: 'scream-it-loud';
  src: url('/src/fonts/sream-it-loud.ttf');
}

:root {
  --orange: #FFA500;
  --purple: #C731C7;
}

p {
  font-size: 1.2em;
  margin-bottom: 0 !important;
  padding: 0.2em;
}

ul {
  font-size: 1.2em;
}

a {
  text-decoration: none;
}

button {
  font-weight: 500;
}

.subpage-content {
  margin: 0 5em;
}

.subpage-content h2 {
  color: rgb(255, 166, 0);
}

.subpage-content h3 {
  color: #12B4EB;
}

.subpage-content a {
  text-decoration: none;
  font-family: "Quicksand", sans-serif;
  color: #C731C7;
}

.subpage-content a:hover {
  text-decoration: none !important;
  color: orange;
}

.subpage {
  padding-bottom: 1em;
  font-family: "Quicksand", sans-serif;
}

.welcome {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1.1em 0 1em 0;
}

.welcome article {
  grid-column: 2/6;
  padding: 1em;
  font-family: "Quicksand", sans-serif;
  font-size: 1.1em;
  text-align: center;
}

.welcome a {
  color: #C731C7;
}

.welcome a:hover {
  color: orange;
  text-decoration: none;
}


.subpage-header {
  height: 400px;
  margin-top: 100px;
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Quicksand", sans-serif;

}

.subpage header h1 {
  font-size: 3.5em;
  text-align: center;
  padding: 0.5em;
}

.subpage-header p {
  padding: 0 7em;
  font-size: 1.4em;
}

/* Side Images for Subpages */

.side-img {
  height: 21vw;
  width: 21vw;
  object-fit: cover;
  border-radius: 18px;
  padding: 3px;
  border: #12B4EB 3px solid;
  box-shadow: 1px 1px 4px grey;
}

/* Layout */


/* Media Queries */
@media screen and (max-width: 926px) {
  .content button {
    font-size: 1.2em;
    background-color: orange;
    color: white;
  }

  .welcome {
    display: flex;
  }

  .subpage-content {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .subpage-content h2 {
    text-align: center;
    
  }

  .subpage-content h3 {
    text-align: center;
    font-size: 1.6em;
  }

  .subpage-content article {
    padding: 1em;
  }

  .side-img {
    width: 30vw;
    height: 30vw;
    margin: 1em 0;
  }
}

@media screen and (max-width: 650px) {
  .side-img {
    width: 50vw;
    height: 50vw;
  }
}