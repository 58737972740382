#services-btn-wide {
    display: block;
}

#services-btn-small {
    display: none;
}

.smile-services-home {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 100%;
    line-height: 2em;
    margin-top: 4px;
    margin-bottom: 4px;
    background-image: url("../../../../assets/background/funky-pattern.png");
    background-attachment: fixed;
    background-size: 70px;
    font-family: "Quicksand", sans-serif;
}

.smile-services-home h2 {
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 3em;
    margin-bottom: 10px;
    padding: 0 0 .2em 0;
    text-align: center;
}

/* LEARN MORE BUTTONS */
.learn-more-btn {
    padding: .2em .7em;
    font-size: 1.1em;
    font-family: "Quicksand", sans-serif;
    color: white;
    border: white 1px solid;
    background: transparent;
    border-radius: 2px;
    transition: box-shadow .2s;
}

.learn-more-btn:hover {
    background: orange;
    box-shadow: 5px 5px black;
}

/* The Smile Club */

.smile-club-home-article {
    grid-column: 4/6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color:rgba(255, 166, 0, .97);
    color: white;
    border-left: white 2px solid;
}

.smile-club-home-header h2 {
    padding: .2em 0 .3em 0;
}

.smile-club-home-article p {
    width: 80%;
    font-size: 1.3em;
    font-family: "Quicksand", sans-serif;
    text-align: center;
}

#learn-more-smile-btn-wrapper {
    padding: 1em 0;
}

.learn-more-smile-btn {
    background: transparent;
    margin: .5em 0;
    padding: .2em .7em;
    font-family: "Quicksand", sans-serif;
    font-size: 1.1em;
    color: white;
    border: white 1px solid;
    border-radius: 2px;
    transition: box-shadow .2s;
}

.learn-more-smile-btn:hover {
    background-color: rgb(199, 12, 199);
    box-shadow: 5px 5px black;
}


/* Our Services */

.services-home-article {
    grid-column: 1/4;
    display: flex;
    height: 100%;
    padding: 2em;
    margin: 0;
    background-color: rgba(199, 12, 199, 0.97);
    color: white;
    border-right: white 2px solid;
}

.services-home-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.services-home-article h2 a{
    color: white;
    text-decoration: none;
    transition: text-shadow .3s;
}

.services-home-article h2 a:hover {
    text-shadow: 3px 3px black;
    text-decoration: none;
    transition: all .2s;
}

.services-home-list {
    margin: 0;
    list-style: square;
}

.services-home-list a {
    color: white;
    text-decoration: none;
}

.services-home-list a:hover {
    color: black;
    text-decoration: none;
}


/* Media Queries */
@media screen and (max-width: 926px){

#services-btn-wide {
    display: none;
}

#services-btn-small {
    display: block;
    padding: 2em 0 1em 0;
}

#learn-more-smile-btn-wrapper {
    padding: 2em 0 1em 0;
}

.smile-services-home {
    display: flex;
    flex-direction: column;
}

.smile-club-home-article, .services-home-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 1em;
    
}

.smile-club-home-article {
    border-left: none;
}

.smile-club-home-article p {
    width: 100%;
}

.services-home-article {
    border-right: none;
    border-bottom: solid 4px white;
}
}