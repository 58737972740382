@import url("https://fonts.googleapis.com/css2?family=Bentham&display=swap");

@font-face {
  font-family: "Scream-It-Loud";
  src: local("Scream-It-Loud"),
    url(./fonts/sream-it-loud.ttf) format("truetype");
}
body {
  padding: 0;
  margin: 0;
}
