.sunnyside-difference-home {
  display: grid;
  grid-template-columns: .5fr 1fr 1fr 1fr 1fr .5fr;
  padding: 2em 0;
  box-shadow: 0 0 5px white;
  margin-bottom: 2em;
}

.sunnyside-difference-home-title {
  grid-column: 1/7;
  text-align: center;
  padding: .5em;
  font-family: 'Shadows Into Light Two', cursive;
  font-size: 3em;
  color: rgb(41, 180, 226);
}

.sunnyside-difference-home-title:after {
  content: "";
  display: block;
  border-bottom: rgb(199, 12, 199) 2px solid;
  width: 25%;
  padding: 10px 0;
  margin: auto;
}

.sunnyside-difference-home-article {
  grid-column: 2/6;
  padding: 0 2em;
}

.sunnyside-difference-home-article p {
  line-height: 40px;
  padding: 1em;
  font-family: "Quicksand", sans-serif;
  font-size: 1.3em;
  text-align: center;
}

.wavy-up {
  margin-top: -200px;
  fill: #12B4EB;
}

.appointment-btn {
  display: none;
}

@media screen and (max-width: 1050px) {
  .sunnyside-difference-home-article {
    grid-column: 1/7;
    padding: 0;
  }
}

@media screen and (max-width: 926px) {
  .sunnyside-difference-home {
    box-shadow: none;
  }

  .sunnyside-difference-home-article p {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .sunnyside-difference-home-title {
    margin: 0;
  }

  .sunnyside-difference-home-article {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .sunnyside-difference-p-1 {
    order: 2;
  }

  .sunnyside-difference-logo {
    order: 1;
  }

  .appointment-btn {
    display: block;
    grid-column: 1/7;
    justify-self: center;
    margin-bottom: 2em;
  }

  .appointment-btn button {
    padding: .5em 1em;
    margin: 1em;
    border: white solid 1px;
    border-radius: 2px;
    font-family: "Quicksand", sans-serif;
  }

  .appointment-btn {
    border: none;
  }
}